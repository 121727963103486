<template>
  <div class="qa-div fs-14">
    <div class="flex items-center">
      <h2 class="q-font flex">
        <span class="mr-1 fs-16 fw-500 text-primary-one">Q{{ serial + 1 }}.</span>
        <textarea class="title-input fw-500 text-primary-one fs-16" v-model="item.title" @change="$parent.updateQuestion(item)" @input="inputAdjust($event)" v-if="!responseMode"></textarea>
        <h2 class="fs-18 text-primary-one" v-if="responseMode">{{ item.title }}</h2>
        <span class="text-danger-one fs-20 ml-2" v-if="responseMode && item.is_required">*</span>
      </h2>
    </div>
    <div class="option-div ml-8 mt-5">
      <div class="flex mb-5 rounded-sm">
        <div class="number-option flex justify-center items-center cursor-pointer text-primary-one"
          v-for="(el, index) in 10" :key="index"
          @click="selectBox(el)"
          :class="{'selected-box' : responseMode && selected == el}">
          {{ el }}
        </div>
      </div>
      <div class="rating-indicator flex justify-between">
        <div v-for="(option, index) in options" :key="index">
          <input type="text" class="option-input survey-opt-text text-primary-one" placeholder="Type here" v-model="option.title" @change="updateOption()" v-if="!responseMode">
          <h2 class="fs-14 text-primary-one" v-if="responseMode">{{ option.title }}</h2>
        </div>
      </div>
    </div>
    <h2 class="text-danger-one fs-14 question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-end question-opt" v-if="!responseMode">
      <div class="flex items-center">
        <h2 class="fs-14 semi-active-text cursor-pointer text-secondary-one">Required Field</h2>
        <label class="survey-switch ml-2">
          <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
          <span class="survey-slider round"></span>
        </label>
      </div>
      <h2 class="fs-14 semi-active-text question-opt-ml cursor-pointer text-secondary-one" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
      <h2 class="fs-14 semi-active-text question-opt-ml cursor-pointer text-secondary-one" @click="$parent.deleteQuestion(item)">Delete</h2>
    </div>
  </div>
</template>

<script>

export default {
  name: "RatingScale",
  props: ['serial', 'item', 'responseMode'],
  data() {
    return {
      opt : [{title: 'Low'}, {title: 'High'}],
      hasError: false,
      selected: null
    }
  },
  created() {
    if(!this.responseMode) {
      this.updateOption();
    }
  },
  computed: {
    options() {
      let data = this.opt
      if (this.item.options) {
        data = this.item.options
      }
      return data
    }
  },
  methods: {
    inputAdjust(e) {
      let el = e.target
      el.style.height = "40px";
      el.style.height = (el.scrollHeight)+"px";
    },
    updateOption() {
      let alarm = false
      if (!this.item.options) {
        this.item.options = this.opt
      }
      this.item.options.every(el => {
        if (el.title == "") {
          alarm = true
        }
      });
      if (alarm) {
        alert("This field shouldn't be empty!")
        return false
      }
      this.$parent.updateQuestion(this.item)
    },
    selectBox(el) {
      this.selected = el

      let obj = {
        question_id: this.item.id,
        answer: this.selected,
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    }
  }
}
</script>

<style scoped>
.q-font {
  font-size: 18px;
}
.option-font {
  font-size: 14px;
}
.grey {
  color: #787878;
}
.e7 {
  color: #e7e7e7;
}
.a5 {
  color: #a5a5a5;
}
.option-div, .add-option {
  margin-left: 33px;
}
.number-option {
  border: 1px solid #414141;
  border-right: none;
}
.number-option:last-child {
  border-right: 1px solid #414141;
}
.question-opt {
  margin-top: 25px;
  margin-left: 40px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.number-option {
  width: 54px;
  height: 40px;
}
.rating-indicator {
  width: 540px;
}
.option-input {
  background: transparent;
  min-width: 180px;
  height: 40px;
  border: 1px solid #414141;
  border-radius: 4px;
  text-align: center;
}
.title-input {
  background: transparent;
  width: 1345px;
  height: 40px;
}
.selected-box {
  background: #2A93C0;
  color: #e7e7e7;
}
@media all and (max-width: 650px){
  .number-option {
    width: 30px;
    height: 35px;
  }
  .rating-indicator{
    width: 300px;
  }
}
</style>
