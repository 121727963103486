<template>
  <div class="qa-div grey fs-14">
    <div class="flex items-center">
      <span class="text-primary-one fs-18 mr-2">Q{{ serial + 1 }}.</span>
      <input class=" fs-18 bg-transparent text-primary-one w-100pr" v-model="item.title" @change="$parent.updateQuestion(item)" v-if="!responseMode" />
      <h2 class="fs-18 grey-text" v-if="responseMode">{{ item.title }}</h2>
      <span class="text-danger-one fs-20  ml-2" v-if="responseMode && item.is_required">*</span>
    </div>
    <div class="i-mt-25" v-click-outside="exitEditMode">
      <div class="fs-14  text-primary-one flex justify-between slider-section">
        <div class="flex item-center" v-for="(option, index) in item.options" :key="index">
          <input v-if="!responseMode" type="text" class="bg-transparent fs-14  text-primary-one inst-placeholder ranger-input survey-opt-text" placeholder="Type here" v-model="option.title"
                 @change="$parent.updateQuestion(item)" :class="{'text-right': index > 0}" @click="optEditableIndex = index" />
          <span v-if="responseMode">{{ option.title }}</span>
          <img src="/images/icons/assessment/minus_circle_outline.svg" alt="icon" class="ml-2 cursor-pointer"
               v-if="parseInt(optEditableIndex) === parseInt(index) && !responseMode"
               @click="removeOption(item, index)">
        </div>
      </div>
      <div class="slider-section relative i-mt-30">
        <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full"
               min="0" max="10" step=".1" type="range" v-model="sliderValue" :disabled="!responseMode" @change="toggleValue()" />
        <div class="progress-track absolute left-0 top-0 bg-primary-five"
             :style="{'width': sliderValue * 10 + '%'}"></div>
      </div>
    </div>
    <h2 class="text-danger-one fs-14  slider-question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-between slider-question-opt" v-if="!responseMode">
      <h2 class="fs-14  text-primary-four cursor-pointer question-opt-link fw-600" @click="addOption()"><span class="mr-1">+</span>Add Option</h2>
      <div class="flex justify-end">
        <div class="flex items-center">
          <h2 class="fs-14 text-secondary-one  cursor-pointer">Required Field</h2>
          <label class="survey-switch ml-2">
            <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
            <span class="survey-slider round"></span>
          </label>
        </div>
        <h2 class="fs-14 text-secondary-one  question-opt-ml cursor-pointer" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
        <h2 class="fs-14 text-secondary-one  question-opt-ml cursor-pointer" @click="$parent.deleteQuestion(item)">Delete</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: ['serial', 'item', 'responseMode'],
  data() {
    return {
      optEditableIndex: null,
      sliderValue: 0,
      hasError: false
    }
  },
  methods: {
    addOption() {
      let option = {}
      if (!this.item.options) {
        this.item.options = [];
      }
      if (this.item.options.length === 5) {
        alert('You can add maximum 5 range');
        return false;
      }
      option.title = "";
      this.item.options.push(option);
      this.$parent.updateQuestion(this.item);
    },
    exitEditMode() {
      this.optEditableIndex = null;
    },
    removeOption(item, index) {
      if(item.options.length > 2) {
        this.$parent.removeOption(item, index);
      } else {
        alert('Minimum 2 range required!');
      }
    },
    toggleValue() {
      let obj = {
        question_id: this.item.id,
        answer: this.sliderValue
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    }
  }
}
</script>

<style scoped>
.slider-question-opt {
  margin-top: 75px;
  margin-left: 40px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.slider-section {
  margin-left: 38px;
  max-width: 700px;
  width: 88%;
}
.value-track {
  /*background: #414141;*/
  width: 100%;
  height: 2px;
}

.progress-track {
  height: 2px;
  /*background: #43738C;*/
}

.range {
  /*background: #000000;*/
  height: 2px;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 12px !important;
  cursor: pointer;
  background: #00A7FE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
.ranger-input {
  width: 115px;
}
</style>
