<template>
  <div class="qa-div fs-14">
    <div class="flex items-center">
      <h2 class="q-font flex">
        <span class="mr-1 fs-16 fw-500 text-primary-one">Q{{ serial + 1 }}.</span>
        <textarea class="title-input fs-16 fw-500 text-primary-one" v-model="item.title" @change="$parent.updateQuestion(item)" @input="inputAdjust($event)" v-if="!responseMode"></textarea>
        <h2 class="fs-18 text-primary-one" v-if="responseMode">{{ item.title }}</h2>
        <span class="text-danger-one fs-20 ml-2" v-if="responseMode && item.is_required">*</span>
      </h2>
    </div>
    <div class="option-div ml-8 mt-5 relative">
      <star-rating
        :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
        :increment="1"
        :show-rating="false"
        :border-width="1"
        border-color="#8D8D8D"
        inactive-color="#F9F9F9"
        :star-size="27"
        :padding="28"
        active-color="#2A93C0"
        @rating-selected ="setRating">
      </star-rating>
    </div>
    <h2 class="text-danger-one fs-14 question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-end question-opt" v-if="!responseMode">
      <div class="flex items-center">
        <h2 class="fs-14 semi-active-text font-roboto cursor-pointer text-secondary-one">Required Field</h2>
        <label class="survey-switch ml-2">
          <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
          <span class="survey-slider round"></span>
        </label>
      </div>
      <h2 class="fs-14 semi-active-text question-opt-ml cursor-pointer text-secondary-one" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
      <h2 class="fs-14 semi-active-text question-opt-ml cursor-pointer text-secondary-one" @click="$parent.deleteQuestion(item)">Delete</h2>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
  name: "Rating",
  props: ['serial', 'item', 'responseMode'],
  components: {
    StarRating
  },
  data() {
    return {
      hasError: false,
      rate: 0
    }
  },
  methods: {
    inputAdjust(e) {
      let el = e.target
      el.style.height = "40px";
      el.style.height = (el.scrollHeight)+"px";
    },
    setRating(value) {
      this.rate = value

      let obj = {
        question_id: this.item.id,
        answer: this.rate,
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    }
  }
}
</script>

<style scoped>
.q-font {
  font-size: 18px;
}
.option-font {
  font-size: 14px;
}
.grey {
  color: #787878;
}
.e7 {
  color: #e7e7e7;
}
.a5 {
  color: #a5a5a5;
}
.option-div, .add-option {
  margin-left: 33px;
}
.option-div div {
  margin-bottom: 30px;
}
.option-div div:last-child {
  margin-bottom: 0px;
}
.question-opt {
  margin-top: 25px;
  margin-left: 40px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.title-input {
  background: transparent;
  width: 1345px;
  height: 40px;
}
</style>
