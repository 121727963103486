<template>
  <div class="qa-div fs-14">
    <div class="flex items-center">
      <h2 class="q-font flex">
        <span class="mr-1 fs-16 fw-500 text-primary-one">Q{{ serial + 1 }}.</span>
        <textarea class="title-input fs-16 fw-500 text-primary-one" v-model="item.title" @change="$parent.updateQuestion(item)" @input="inputAdjust($event)" v-if="!responseMode"></textarea>
        <h2 class="fs-18 text-primary-one" v-if="responseMode">{{ item.title }}</h2>
        <span class="text-danger-one fs-20 ml-2" v-if="responseMode && item.is_required">*</span>
      </h2>
    </div>
    <div class="option-div ml-8 mt-5">
      <div class="flex items-start" v-for="(option, index) in item.options" :key="index">
        <div class="survey-option-checkbox cursor-pointer" :class="{'survey-option-checked': selection == index}" @click="toggleOptionSelect(index)"></div>
        <span class="mr-1 text-primary-one">{{ bullets[index]+'.' }}</span>
        <textarea class="option-input survey-opt-text text-primary-one" placeholder="Type here" v-model="option.title" @change="$parent.updateQuestion(item)" @input="inputAdjust($event), widthAdjust($event)" v-if="!responseMode"></textarea>
        <h2 class="option-input text-primary-one" v-if="responseMode">{{ option.title }}</h2>
        <!-- <input class="option-input" type="text" v-model="option.title" @change="updateQuestion()" oninput="this.style.width = ((this.value.length) * 8) + 'px';"> -->
        <img v-if="!responseMode" src="/images/icons/survey/trash_empty.svg" alt="trash" class="survey-trash-img cursor-pointer pt-1" @click="$parent.removeOption(item, index)">
      </div>
    </div>
    <h2 class="text-danger-one fs-14 question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-between ml-8" v-if="!responseMode">
      <h2 class="fs-14 text-primary-four cursor-pointer fw-600" @click="addOption()"><span class="mr-1">+</span>Add Option</h2>
      <div class="flex justify-end">
        <div class="flex items-center">
          <h2 class="fs-14 text-secondary-one cursor-pointer">Required Field</h2>
          <label class="survey-switch ml-2">
            <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
            <span class="survey-slider round"></span>
          </label>
        </div>
        <h2 class="fs-14 question-opt-ml cursor-pointer text-secondary-one" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
        <h2 class="fs-14 question-opt-ml cursor-pointer text-secondary-one" @click="$parent.deleteQuestion(item)">Delete</h2>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Selection",
  props: ['serial', 'item', 'responseMode'],
  data() {
    return {
      bullets: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
      hasError: false,
      selection: null
    }
  },
  methods: {
    inputAdjust(e) {
      let el = e.target
      el.style.height = "40px";
      el.style.height = (el.scrollHeight)+"px";
    },
    widthAdjust(e) {
      let el = e.target
      el.style.width = ((el.value.length) * 8) + 'px'
    },
    addOption() {
      let option = {}
      if (!this.item.options) {
        this.item.options = []
      }
      if (this.item.options.length == 10) {
        alert('You can add maximum 10 options')
        return false
      }
      option.title = ""
      this.item.options.push(option)
      this.$parent.updateQuestion(this.item)
    },
    toggleOptionSelect(index) {
      this.selection = index

      let obj = {
        question_id: this.item.id,
        answer: this.selection,
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    }
  }
}
</script>

<style scoped>
.q-font {
  font-size: 18px;
}
.option-font {
  font-size: 14px;
}
.grey {
  color: #787878;
}
.e7 {
  color: #e7e7e7;
}
.a5 {
  color: #a5a5a5;
}
.option-div, .add-option {
  margin-left: 33px;
}
.option-div div {
  margin-bottom: 10px;
}
.option-div div:last-child {
  margin-bottom: 0px;
}
.question-opt {
  margin-top: 25px;
  margin-left: 40px;
}
.option-div .checkbox {
  margin-top: 2px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.title-input {
  background: transparent;
  width: 1345px;
  height: 40px;
}
.option-input {
  background: transparent;
  min-width: 200px;
  max-width: 1345px;
  height: 40px;
}
</style>
