<template>
  <div class="qa-div fs-14">
    <div class="flex items-center">
      <span class="text-primary-one fs-18 mr-2">Q{{ serial + 1 }}.</span>
      <input class="text-primary-one fs-18 bg-transparent grey-text w-100pr" v-model="item.title" @change="$parent.updateQuestion(item)" v-if="!responseMode" />
      <h2 class="fs-18 grey-text " v-if="responseMode">{{ item.title }}</h2>
      <span class="text-danger-one fs-20 ml-2" v-if="responseMode && item.is_required">*</span>
    </div>
    <div class="flex items-center mt-5" v-if="item.options && item.options.length > 0">
      <select class="bg-transparent option-dropdown fs-14 text-primary-one py-2 pl-1 pr-5 cursor-pointer cursor-pointer" v-model="optedItem" @change="toggleOption()">
        <option class="fs-14 text-primary-one cursor-pointer" value="" disabled>Select Option</option>
        <option v-for="(option, index) in item.options" :key="index" class="fs-14 text-primary-one cursor-pointer" :value="index">{{ option.title }} </option>
      </select>
    </div>
    <div class="flex items-center mt-5" v-for="(option, index) in item.options" :key="index" v-if="!responseMode">
      <h2 class="fs-14 text-primary-one ml-10">Option {{ index + 1 }}:</h2>
      <input type="text" class="bg-transparent fs-14 text-primary-one opt-input i-ml-30 survey-opt-text" placeholder="Type here" v-model="option.title"
             @change="$parent.updateQuestion(item)">
      <img v-if="!responseMode" src="/images/icons/survey/trash_empty.svg" alt="trash" class="survey-trash-img cursor-pointer" @click="$parent.removeOption(item, index)">
    </div>
    <h2 class="text-danger-one fs-14 question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-between question-opt" v-if="!responseMode">
      <h2 class="fs-14 text-primary-four cursor-pointer question-opt-link fw-600" @click="addOption()"><span class="mr-1">+</span>Add Option</h2>
      <div class="flex justify-end">
        <div class="flex items-center">
          <h2 class="fs-14 text-secondary-one cursor-pointer question-opt-link">Required Field</h2>
          <label class="survey-switch ml-2">
            <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
            <span class="survey-slider round"></span>
          </label>
        </div>
        <h2 class="fs-14 text-secondary-one question-opt-ml cursor-pointer" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
        <h2 class="fs-14 text-secondary-one question-opt-ml cursor-pointer" @click="$parent.deleteQuestion(item)">Delete</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dropdown",
  props: ['serial', 'item', 'responseMode'],
  data() {
    return {
      optedItem: "",
      hasError: false
    }
  },
  methods: {
    addOption() {
      let option = {}
      if (!this.item.options) {
        this.item.options = [];
      }
      if (this.item.options.length === 10) {
        alert('You can add maximum 10 options');
        return false;
      }
      option.title = "";
      this.item.options.push(option);
      this.$parent.updateQuestion(this.item);
    },
    toggleOption() {
      let obj = {
        question_id: this.item.id,
        answer: this.optedItem
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    }
  }
}
</script>

<style scoped>
.sr-ml-40 {
  margin-left: 40px;
}
.question-dropdown {
  margin-left: 40px;
  border: 1px solid #414141;
  outline: none;
  border-radius: 4px;
  background: #232326;
}
.question-dropdown:focus {
  border: 1px solid #414141;
  outline: none;
}
.question-opt {
  margin-top: 25px;
  margin-left: 40px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.opt-input {
  border: 1px solid #414141;
  padding: 6px 8px;
  border-radius: 4px;
  min-width: 190px;
  max-width: 1345px;
  height: 40px;
}
.opt-input::placeholder {
  color: #787878;
}
.ml-40px {
  margin-left: 40px;
}
.option-dropdown {
  margin-left: 40px;
  border: 1px solid #414141;
  outline: none;
  border-radius: 4px;
}
</style>
