<template>
  <div class="qa-div font-poppins fs-14">
    <div class="flex items-center">
      <span class="text-primary-one  fs-18 mr-2">Q{{ serial + 1 }}.</span>
      <input class=" fs-18 bg-transparent text-primary-one w-100pr" v-model="item.title" @change="$parent.updateQuestion(item)" v-if="!responseMode" />
      <h2 class="fs-18 text-primary-one " v-if="responseMode">{{ item.title }}</h2>
      <span class="text-danger-one fs-20  ml-2" v-if="responseMode && item.is_required">*</span>
    </div>
    <div class="i-mt-25 opt-section flex items-center">
      <div class="wrapper flex items-center">
        <img src="/images/icons/survey/checkbox_checked.svg" alt="image" class="mr-2 cursor-pointer" v-if="!responseMode">
        <h2 class="fs-14  text-primary-one wtitle">Start Date:</h2>
        <VueCtkDateTimePicker v-model="start_date"
                              :noClearButton="true"
                              :dark="false"
                              :color="'#1C1C1D'"
                              :button-color="'#1C1C1D'"
                              ref="start_date"
                              :no-label="true"
                              :disabled="!responseMode"
                              :format="'MM-DD-YYYY'"
                              :formatted="'ll'"
                              :onlyDate="true"
                              :no-button-now="true" />
      </div>

      <div class="wrapper flex i-ml-30 items-center" v-if="!responseMode || (responseMode && item.options[0].visible_end_date)">
        <div class="datetime-checkbox cursor-pointer" v-if="!item.options[0].visible_end_date && !responseMode" @click="toggleEndCheckbox()"></div>
<!--        <img src="/images/icons/survey/checkbox.svg" alt="image" class="mr-2 cursor-pointer">-->
        <img src="/images/icons/survey/checkbox_checked.svg" alt="image" class="mr-2 cursor-pointer" v-if="item.options[0].visible_end_date && !responseMode"
             @click="toggleEndCheckbox()">
        <h2 class="fs-14  text-primary-one wtitle">End Date:</h2>
        <VueCtkDateTimePicker v-model="end_date"
                              :noClearButton="true"
                              :dark="false"
                              :color="'#1C1C1D'"
                              :button-color="'#1C1C1D'"
                              ref="start_date"
                              :disabled="!responseMode"
                              :no-label="true"
                              :format="'MM-DD-YYYY'"
                              :formatted="'ll'"
                              :onlyDate="true"
                              :no-button-now="true"/>
      </div>
    </div>
    <h2 class="text-danger-one fs-14  question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-end question-opt" v-if="!responseMode">
      <div class="flex items-center">
        <h2 class="fs-14 text-secondary-one  cursor-pointer">Required Field</h2>
        <label class="survey-switch ml-2">
          <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
          <span class="survey-slider round"></span>
        </label>
      </div>
      <h2 class="fs-14 text-secondary-one  question-opt-ml cursor-pointer" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
      <h2 class="fs-14 text-secondary-one  question-opt-ml cursor-pointer" @click="$parent.deleteQuestion(item)">Delete</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateTime",
  props: ['serial', 'item', 'responseMode'],
  data() {
    return {
      start_date: null,
      end_date: null,
      hasError: false
    }
  },
  watch: {
    start_date: function (val) {
      this.toggleDateValue();
    },
    end_date: function (val) {
      this.toggleDateValue();
    }
  },
  methods: {
    toggleEndCheckbox() {
      this.item.options[0].visible_end_date = !this.item.options[0].visible_end_date;
      this.$parent.updateQuestion(this.item);
    },
    toggleDateValue() {
      let obj = {
        question_id: this.item.id,
        answer: {
          start_date: this.start_date,
          end_date: this.end_date
        }
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    }
  },
}
</script>

<style scoped>
.question-dropdown {
  margin-left: 40px;
  border: 1px solid #414141;
  outline: none;
  border-radius: 4px;
}
.question-dropdown:focus {
  border: 1px solid #414141;
  outline: none;
}
.question-opt {
  margin-top: 25px;
  margin-left: 40px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.opt-section {
  margin-left: 40px;
}
.wtitle {
  width: 110px;
}
.datetime-checkbox {
  width: 20px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #C9C9C9;
  margin-right: 8px;
}

@media all and (max-width: 600px){
  .wrapper{
    flex-wrap: wrap;
  }
}
</style>
