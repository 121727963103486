<template>
  <div class="font-poppins">
    <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
    <h2 class="fw-600 fs-18 text-primary-one">Question Type</h2>
    <div class="info-div" :class="{'pointer-events-none': survey.responses_count > 0}">
      <div class="flex items-center cursor-pointer py-5 i-border-b-1 border-secondary-five text-secondary-one" v-for="(item, index) in surveyCommonData.questionType" :key="index" @click="addQuestion(item)">
        <img :src="getOptionIcon(item.icon)" alt="image" class="mr-3">
        <span class="text-secondary-one fw-400">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import survey from '../../common/survey';
import {mapGetters} from "vuex";

export default {
  name: "OptionInLeft",
  created() {
    this.surveyCommonData = survey;
  },
  data() {
    return {
      surveyCommonData: null
    }
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey"
    })
  },
  methods: {
    getOptionIcon(file) {
      return require('../../assets/images/icons/survey/'+ file);
    },
    addQuestion(item) {
      this.$Progress.start();
      let data = {
        survey_id: this.$route.params.survey_id,
        type: item.serial
      }

      if(data.type === 8) {
        data.options = this.addSliderRangeOption();
      }
      if(data.type === 9) {
        data.options = this.addDateRangeOption();
      }
      this.$store.dispatch("survey/storeQuestion", data).then(response => {
        this.$Progress.finish();
      });
    },
    addSliderRangeOption() {
      return [
        {
          title: 'Standard',
        },
        {
          title: 'High',
        }
      ];
    },
    addDateRangeOption() {
      return [
        {
          visible_start_date: true,
          visible_end_date: false
        }
      ];
    }
  }
}
</script>

<style scoped>
.i-insider-logo {
  margin-bottom: 45px;
}

.create-button span {
  color: #656565;
}

.info-div {
  height: 835px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.leading-info {
  color: #C4C4C4 !important;
}

.share-link {
  margin-bottom: 20px;
  cursor: pointer;
}

.social img {
  margin-right: 30px;
  cursor: pointer;
}
.question-opt {
  border-bottom: 1px solid #28282B;
}
.create-button {
  margin-top: 25px;
}
.py-question-type-opt:hover {
  background: #232326;
}
</style>
