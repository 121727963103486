<template>
  <div class="qa-div fs-14">
    <div class="flex items-center">
      <span class="text-primary-one fs-18 mr-2">Q{{ serial + 1 }}.</span>
      <input class="fs-18 bg-transparent text-primary-one w-100pr" v-model="item.title" @change="$parent.updateQuestion(item)" v-if="!responseMode" />
      <h2 class="fs-18 grey-text" v-if="responseMode">{{ item.title }}</h2>
      <span class="text-danger-one fs-20 grey-text ml-2" v-if="responseMode && item.is_required">*</span>
    </div>
    <div class="mt-5 opt-section" v-if="item.options && item.options.length > 0 && responseMode">
      <div class="flex items-center" v-for="(optItem, optIndex) in item.options" :key="optIndex" :class="{'i-mt-25': optIndex > 0}">
        <h2 class="flex justify-between items-center fs-14 font-roboto text-primary-one mr-5 options">
          <span>{{ optItem.title }}:</span>
          <div class="flex justify-end">
            <img src="/images/icons/survey/short_down.svg" alt="" class="cursor-pointer" v-show="optIndex != item.options.length - 1" @click="pullDown(optIndex)">
            <img src="/images/icons/survey/short_up.svg" alt="" class="cursor-pointer ml-5" v-show="optIndex != 0" @click="pullUp(optIndex)">
          </div>
        </h2>
        <!-- <select class="bg-transparent question-dropdown fs-14 font-roboto grey-text py-2 pl-1 pr-5 cursor-pointer" v-model="optedItem[optIndex]" @change="toggleOption()">
          <option v-for="(optSerial, index) in item.options.length" :key="index" class="fs-14 font-roboto grey-text cursor-pointer" :value="optSerial">{{ posText[index] }} </option>
        </select> -->
      </div>
    </div>
    <div class="flex items-center mt-5" v-if="item.options && item.options.length > 0 && !responseMode">
      <select class="bg-transparent option-dropdown fs-14 text-primary-one py-2 pl-1 pr-5 cursor-pointer">
        <option class="fs-14 text-primary-one cursor-pointer" value="" disabled selected>Select Option</option>
        <option v-for="(option, index) in item.options" :key="index" class="fs-14 cursor-pointer">{{ option.title }} </option>
      </select>
    </div>
    <div class="flex items-center i-mt-25 opt-section" v-for="(option, index) in item.options" :key="index" v-if="!responseMode">
      <h2 class="fs-14 text-primary-one mr-4">Option {{ index + 1 }}:</h2>
      <input type="text" class="bg-transparent fs-14 opt-select-input text-primary-one px-2" placeholder="Type here" v-model="option.title"
             @change="$parent.updateQuestion(item)">
      <img v-if="!responseMode" src="/images/icons/survey/trash_empty.svg" alt="trash" class="survey-trash-img cursor-pointer" @click="$parent.removeOption(item, index)">
    </div>
    <h2 class="text-danger-one fs-14 question-opt" v-if="responseMode && hasError">This field is required</h2>
    <div class="flex justify-between question-opt" v-if="!responseMode">
      <h2 class="fs-14 text-primary-four question-opt-link fw-600 cursor-pointer"  @click="addOption()"><span class="mr-1">+</span>Add Option</h2>
      <div class="flex justify-end">
        <!-- <div class="flex items-center">
          <h2 class="fs-14 semi-active-text font-roboto cursor-pointer question-opt-link">Required Field</h2>
          <label class="survey-switch ml-2">
            <input type="checkbox" v-model="item.is_required" @change="$parent.updateQuestion(item)">
            <span class="survey-slider round"></span>
          </label>
        </div> -->
        <h2 class="fs-14 text-secondary-one question-opt-ml cursor-pointer" @click="$parent.duplicateQuestion(item)">Duplicate</h2>
        <h2 class="fs-14 text-secondary-one question-opt-ml cursor-pointer" @click="$parent.deleteQuestion(item)">Delete</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderRanking",
  props: ['serial', 'item', 'responseMode'],
  created() {
    if (this.item.options) {
      this.item.options.forEach((optItem, index) => {
        this.optedItem.push((index + 1));
      });
    }
  },
  data() {
    return {
      optedItem: [],
      posText: ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth'],
      hasError: false
    }
  },
  methods: {
    addOption() {
      let option = {}
      if (!this.item.options) {
        this.item.options = [];
      }
      if (this.item.options.length === 10) {
        alert('You can add maximum 10 options');
        return false;
      }
      option.title = "";
      this.item.options.push(option);
      this.$parent.updateQuestion(this.item);
    },
    toggleOption() {
      console.log(this.optedItem);
      let obj = {
        question_id: this.item.id,
        answer: this.optedItem
      };
      this.hasError = false;
      this.$emit("responseFromChild", obj);
    },
    pullUp(index) {
      let options = this.item.options;
      var up = options[index];
      var down = options[index - 1];
      var optUp = this.optedItem[index]
      var optDown = this.optedItem[index - 1]
      this.$set(this.optedItem, index - 1, optUp)
      this.$set(this.optedItem, index, optDown)
      this.$set(options, index - 1, up)
      this.$set(options, index, down)
      this.toggleOption()

    },
    pullDown(index) {
      let options = this.item.options;
      var down = options[index];
      var up = options[index + 1];
      var optDown = this.optedItem[index];
      var optUp = this.optedItem[index + 1];
      this.$set(this.optedItem, index + 1, optDown)
      this.$set(this.optedItem, index, optUp)
      this.$set(options, index + 1, down)
      this.$set(options, index, up)
      this.toggleOption()
    }
  }
}
</script>

<style scoped>
.question-dropdown {
  margin-left: 40px;
  border: 1px solid #414141;
  outline: none;
  border-radius: 4px;
  background: #232326;
}
.question-dropdown:focus {
  border: 1px solid #414141;
  outline: none;
}
.question-opt {
  margin-top: 25px;
  margin-left: 40px;
}
.question-opt-ml {
  margin-left: 50px;
}
.question-opt-link:hover {
  color: #e7e7e7;
}
.opt-select-input {
  border: 1px solid #414141;
  border-radius: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.opt-select-input::placeholder {
  color: #787878;
}
.opt-section {
  margin-left: 40px;
  width: calc(100% - 40px);
}
.options {
  border: 1px solid #414141;
  border-radius: 4px;
  padding: 15px 12px;
}
.options span {
  min-width: 360px;
  margin-right: 50px;
}
.options div {
  min-width: 36px;
}
.option-dropdown {
  margin-left: 40px;
  border: 1px solid #414141;
  outline: none;
  border-radius: 4px;
}

@media all and (max-width: 600px){
  .options span {
    min-width: 200px;
  }
}
@media all and (max-width: 500px){
  .options span {
    min-width: 150px;
  }
}
</style>
